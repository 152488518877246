import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/frontend/site/src/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/src/lib/store-config/context/storeConfig.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/node_modules/.pnpm/@labdigital+federated-token-react@1.0.0_graphql@16.9.0_react@19.0.0-rc-69d4b800-20241021/node_modules/@labdigital/federated-token-react/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.24.0_next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.4_lbhlxfrtkus52edjbyjept5q7y/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.24.0_next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.4_lbhlxfrtkus52edjbyjept5q7y/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@19._5ffssigtuqhpvo7bbpqgmhjoke/node_modules/next/font/local/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/TTCommonsProExp-Rg.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TTCommonsProExp-It.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/TTCommonsProExp-Md.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TTCommonsProExp-MdIt.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/TTCommonsProExp-DemiBd.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TTCommonsProExp-DemiBd.woff2\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/TTCommonsProExp-Bd.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TTCommonsProExp-BdIt.woff2\",\"weight\":\"700\",\"style\":\"italic\"}],\"variable\":\"--font-family\"}],\"variableName\":\"TTCommonsProExp\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@19._5ffssigtuqhpvo7bbpqgmhjoke/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-family\"}],\"variableName\":\"OpenSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@19._5ffssigtuqhpvo7bbpqgmhjoke/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Geologica\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-family-geologica\"}],\"variableName\":\"GeologicaFont\"}");
