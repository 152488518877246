"use client";

import { useAuth } from "@labdigital/federated-token-react";
import {
	ClientGqlFetcherProvider,
	initClientFetcher,
} from "@labdigital/graphql-fetcher";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { AbstractIntlMessages } from "next-intl";
import { NextIntlClientProvider } from "next-intl";
import type { ReactNode } from "react";
import { useState } from "react";
import { ContentPreviewProvider } from "~/lib/cms-preview.client";
import type { Locale } from "~/lib/i18n/types";
import type { StoreConfig } from "~/lib/store-config/context";
import { StoreConfigProvider } from "~/lib/store-config/context";

type Props = {
	children?: ReactNode;
	locale: Locale;
	storeConfig: StoreConfig;
	apiHostname: string;
	messages: AbstractIntlMessages;
	draftMode: boolean;
};

const MINUTES = 60 * 1000;

/**
 * Providers used to add context to client components, use this to wrap the first client component it hits
 */
export const Providers = ({
	children,
	storeConfig,
	apiHostname,
	locale,
	messages,
	draftMode,
}: Props): ReactNode => {
	const { checkToken, validateLocalToken } = useAuth();
	// Initialize the fetcher with the API hostname which is only available as a prop
	// useState is the recommended way to hold a stable value so we only initialize the fetcher once
	const [fetcher] = useState(() =>
		initClientFetcher(`${apiHostname}/graphql`, {
			persistedQueries: false,
		}),
	);

	// Also initializing the query client in a stable value since we need access to the auth context
	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						staleTime: 15 * MINUTES,
					},
					mutations: {
						onSettled: () => {
							// Check and validate whether the newly set token is valid
							validateLocalToken();
						},
					},
				},
			}),
	);

	return (
		<StoreConfigProvider config={storeConfig}>
			<NextIntlClientProvider
				locale={locale}
				messages={messages}
				timeZone="UTC"
			>
				<ClientGqlFetcherProvider
					fetcher={async (...args) => {
						// Always check and refresh the token first before attempting to do a client action
						await checkToken();
						return fetcher(...args);
					}}
				>
					<QueryClientProvider client={queryClient}>
						{draftMode ? (
							<ContentPreviewProvider fetcher={fetcher}>
								{children}
							</ContentPreviewProvider>
						) : (
							children
						)}
					</QueryClientProvider>
				</ClientGqlFetcherProvider>
			</NextIntlClientProvider>
		</StoreConfigProvider>
	);
};
