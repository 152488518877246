"use client";

import { ContentPreviewProvider as ContentPreviewProviderContentful } from "@evolve-storefront/contentful-preview/provider";
import { ContentPreviewProvider as ContentPreviewProviderStoryblok } from "@evolve-storefront/storyblok-preview/provider";
import type { ClientFetcher } from "@labdigital/graphql-fetcher";
import type { ReactNode } from "react";

type Props = {
	children: ReactNode;
	fetcher: ClientFetcher;
};

const EVOLVE_CMS = process.env.NEXT_PUBLIC_EVOLVE_CMS ?? "contentful";

export const ContentPreviewProvider = ({
	children,
	fetcher,
}: Props): ReactNode | undefined => {
	switch (EVOLVE_CMS) {
		case "contentful":
			return (
				<ContentPreviewProviderContentful fetcher={fetcher}>
					{children}
				</ContentPreviewProviderContentful>
			);
		case "storyblok":
			return (
				<ContentPreviewProviderStoryblok fetcher={fetcher}>
					{children}
				</ContentPreviewProviderStoryblok>
			);
	}
};
